<template>
  <v-card>
    <v-card-title>
      <h5>دخول سكنة المنطقة</h5>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#098064"
            small
            class="mx-2"
            v-bind="attrs"
            style="color: white"
            v-on="on"
            @click="dialogModal(true, 'create','citizen')"
          >
            تصدير
            <v-icon
              right
            >
              mdi-file-excel
            </v-icon>
          </v-btn>
        </template>
        <span>  تصدير  الاشخاص والمركبات  </span>
      </v-tooltip>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="generals"
      :options.sync="options"
      :server-items-length="generalsTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50, 100],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey"
                          @update:filters="fetch"
        ></datatable-header>
      </template>

      <template v-slot:item.user.name="{ item }">
        <v-chip
          v-if="item.user"
          label
          small
        >
          {{ item.user.name }}
        </v-chip>
      </template>
      <template v-slot:item.people.identifier.name="{ item }">
        <v-chip
          v-if="item.people.identifier"
          label
          small
          class="text-wrap my-2 text-center"
          :style="{height: 'fit-content', 'display': 'block'}"
        >
          {{ item.people.identifier.name }}
        </v-chip>
      </template>
      <template v-slot:item.created_at="{ item }">
        <v-chip
          v-if="item.created_at"
          label
          small
        >
          {{ item.created_at | format_date }}
        </v-chip>
      </template>
      <template v-slot:item.people.name="{ item }">
        <v-chip
          label
          small
          class="text-wrap my-2 text-center"
          :style="{height: 'fit-content', 'display': 'block'}"
        >
          {{ item.people.name }}

          <v-icon
            v-can="'update_peoples'"
            class="mx-2"
            @click="editPeople(item.people.id)"
          >
            mdi-pencil
          </v-icon>
        </v-chip>
      </template>
      <template v-slot:item.enter="{ item }">
        <v-chip
          :color=" item.enter ? 'success' : 'error'"
          label
          small
        >
          {{ item.enter ? 'نعم' : ' كلا' }}
        </v-chip>
      </template>
      <template v-slot:item.car.expire_date="{ item }">
        <v-chip
          v-if="item.car.expire_date"
          :color="Date.now() > new Date(item.expire_date) ? 'error': ''"
          label
          small
        >
          {{ item.car.expire_date | format_date }}
        </v-chip>
        <v-chip
          v-else
          label
          small
        >
          لا يوجد
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mx-2"
          tile
          small
          color="info"
          @click="dialogModal(true, 'update', item.id)"
        >
          <v-icon left>
            mdi-pencil
          </v-icon>
          {{ $('update') }}
        </v-btn>

        <v-btn
          tile
          small
          color="error"
          @click="dialogModal(true, 'delete', item.id)"
        >
          <v-icon left>
            mdi-delete
          </v-icon>
          {{ $('delete') }}
        </v-btn>
      </template>
    </v-data-table>
    <export-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></export-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'
import ExportDialog from '@/views/pages/statistics/general/export.vue'

import { ObjectToQuery } from '@/plugins/helper'
import { getFilters, getOptions } from '@/components/dataTables/helper'
import router from '@/router'

export default {
  name: 'GeneralCitizen',
  components: { DatatableHeader, ExportDialog },
  data: () => ({
    translationKey: 'pages.generals',
    filters: false,
    generals: [],
    generalsTotal: 0,
    loading: true,
    options: {
      sortBy: ['created_at'],
      sortDesc: [true],
    },
    dialogData: {
      dialog: false,
      type: 'details',
      params: {},
      export_type: 'citizen',
    },
    tempOptions: '',
    citizen: 1,
    search: '',
    headers: [
      {
        text: 'id',
        align: 'center',
        sortable: false,
        value: 'people.identification_number',
        sort: true,
        filter: {
          menu: false,
          type: 'number',
        },
      },
      {
        text: 'name',
        value: 'people.name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'identifier',
        value: 'people.identifier.name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'select',
          api: async $axios => {
            const check = await $axios.get('/identifiers')

            return check.data.data.map(identifier => identifier.name)
          },
          options: [],
        },
      },
      {
        text: 'number',
        value: 'car.number',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'type',
        value: 'car.type',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'expire_date',
        value: 'car.expire_date',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
      {
        text: 'checkpoint',
        value: 'checkpoint.name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'select',
          api: async $axios => {
            const check = await $axios.get('/checkpoints')

            return check.data.data.map(checkpoint => checkpoint.name)
          },
          options: [],
        },
      },
      {
        text: 'username',
        value: 'user.name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'canEntry',
        value: 'enter',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'switch',
          label: 'السماح بالدخول',
        },
      },
      {
        text: 'enterDate',
        value: 'created_at',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
    ],
  }),
  setup() {
    return {}
  },
  mounted() {
    this.options = getOptions(this.$route, this.options)
    this.fetch()
  },
  dialogModal(dialog, type = this.dialogData.type, export_type) {
    this.dialogData.dialog = dialog
    this.dialogData.type = type
    // eslint-disable-next-line camelcase
    this.dialogData.export_type = export_type
  },
  methods: {
    async editPeople(id) {
      await router.push(`/people/${id}`)
    },
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options)
        await this.$router.replace({ query: this.options })
      }
      window.scrollTo(0, 0)
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage)
      this.loading = true
      const filters = getFilters(this.headers)
      const query = ObjectToQuery({
        ...this.options,
        citizen: this.citizen,
        filters,
      })
      const response = await axios.get(`/carEntries/dt?${query}`)
      this.generals = response.data.data.data
      this.generalsTotal = response.data.data.total
      this.loading = false
    },
    // eslint-disable-next-line camelcase
    dialogModal(dialog, type = this.dialogData.type, export_type) {
      this.dialogData.dialog = dialog
      this.dialogData.type = type
      const filters = getFilters(this.headers);
      this.dialogData.params = {
        type: this.type,
        citizen: this.citizen,
        filters,
      }
      // eslint-disable-next-line camelcase
      this.dialogData.export_type = export_type
    },
    $(key) {
      return this.$t(`${this.translationKey}.${key}`)
    },
  },

}
</script>
