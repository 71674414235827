<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" color="primary" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        class="form-primary"
      >
        <span v-if="isCreate" class="">تصدير حسب التاريخ</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row class="">
              <v-col
                cols="12"
                sm="6"
              >
                <p style="font-weight: bold">
                  من :
                </p>
                <v-date-picker
                  v-model="form.from_date"
                  class=""
                  value-type="format"
                  :placeholder="$t('dataTable.headers.enter_date')"
                  format="YYYY-MM-DD"
                  type="date"
                ></v-date-picker>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <p style="font-weight: bold">
                  الى :
                </p>
                <v-date-picker
                  v-model="form.to_date"
                  class=""
                  value-type="format"
                  :placeholder="$t('dataTable.headers.enter_date')"
                  format="YYYY-MM-DD"
                  type="date"
                ></v-date-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light"
            outlined
            @click="dialogModal(false)"
          >
            {{ $t('form.cancel') }}
          </v-btn>

          <v-btn
            v-if="isCreate"
            color="primary"
            :disabled="!valid || submitLoading"
            :loading="submitLoading"
            @click="submit"
          >
            تصدير
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'
import { ObjectToQuery } from '@/plugins/helper'

export default {
  name: 'ExportDialog',
  components: { },
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        export_type: '',
        params: {},
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    form: {
      from_date: '',
      to_date: '',
      type: '',
    },
  }),
  setup() {

  },
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    rules() {
      const rules = {}
      rules.date = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.from_date`)]),
        v => {
          const date = new Date(v)

          return date.toString() !== 'Invalid Date' || this.$t('form.date_invalid', [this.$t(`${this.translationKey}.modal.to_date`)])
        },
      ]

      return rules
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true
          if (!val.dialog) {
            this.resetForm()
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false
        }
      },
    },
  },

  methods: {
    // eslint-disable-next-line consistent-return
    async submit() {
      try {
        this.submitLoading = true
        if (!(this.form.from_date && this.form.to_date)) {
          return 0
        }
        const query = ObjectToQuery({
          ...this.data.params,
          from_date: this.form.from_date,
          to_date: this.form.to_date,
        });
        await axios.get(`/carEntries/export?${query}`, {
          responseType: 'arraybuffer',
        })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          })
        this.dialogModal(false)
        await toast.dispatch('success', this.$t('form.success.create'))
        this.$emit('update:table')
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.form = {
        from_date: '',
        to_date: '',
        type: '',
      }
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters)
    },
  },
}
</script>

<style scoped>

</style>
